<template>
    <div class="course">
        <b-card no-body class="mb-1">
            <b-card-header class="pb-0">
                <strong class="mr-auto">فلترة حسب</strong>
                <b-button type="submit" class="mr-1" variant="primary" @click="getCourses(filterDto)">فلترة</b-button>

                <b-button variant="secondary" @click="resetFilter">تهيئة</b-button>
            </b-card-header>
            <b-card-body class="pb-50">
                <b-row>
                    <!-- <pre>{{ universityList }}</pre> -->
                    <b-col cols="12" md="3">
                        <ek-input-select label="جامعة" placeholder=" اختر جامعة" :options="[
                            { id: 0, name: 'الكل' },
                            ...universityList,
                        ]" v-model="filterDto.universityId" name="select" :clearable="true" />
                    </b-col>

                    <b-col cols="12" md="3">
                        <ek-input-select label="الكلية" placeholder="اختر الكلية "
                            :options="[{ id: 0, name: 'الكل' }, ...facultyList]" name="select" v-model="filterDto.facultyId"
                            :clearable="true" />
                    </b-col>

                    <b-col cols="12" md="3">
                        <ek-input-select label="السنة" placeholder="اختر السنة "
                            :options="[{ id: 0, name: 'الكل' }, ...years]" name="select" v-model="filterDto.yearId"
                            :clearable="true" />
                    </b-col>

                    <b-col cols="12" md="3">
                        <ek-input-select label="الفصل" placeholder="اختر الفصل "
                            :options="[{ id: 0, name: 'الكل' }, ...semesters]" name="select" v-model="filterDto.semesterId"
                            :clearable="true" />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-row class="courses" v-if="universityList &&
            branches &&
            facultyList &&
            years &&
            semesters
            ">
            <b-col lg="3" md="6" v-for="item in course.courses" :key="item.id">
                <b-card id="my-card">
                    <template #header class="p-0 position-relative">
                        <div class="free d-flex justify-content-center align-items-center rounded text-white position-absolute"
                            :class="item.isFree == true ? 'bg-primary' : 'bg-danger'
                                " style="height: 30px; width: 60px">
                            {{ item.isFree == true ? "مجاني" : "مدفوع" }}
                        </div>
                        <img :style="!item.url ? 'object-fit: contain' : ''" :src="item.url ? $store.getters['app/domainHost'] +
                            '/' +
                            item.url: appName
                            " alt="" srcset="" />
                    </template>
                    <b-card-body body-class="p-0">
                        <div class="mt-1">
                            <span class="w-100 d-flex justify-content-start align-items-start mt-2">
                                <unicon name="video"></unicon>
                                <span class="px-1">
                                    ({{ item.videoCount }})
                                </span>
                                |
                                <unicon name="folder" class="pl-1"></unicon>
                                <span class="px-1">
                                    ({{ item.documentCount }})
                                </span>
                            </span>
                            <h4 class="mt-1">{{ item.name }}</h4>
                            <h5 class="mt-1" v-if="subjectList && subjectList.subjects">
                                {{
                                    subjectList.subjects.find(
                                        (sub) => sub.id == item.subjectId
                                    ).name
                                }}
                            </h5>
                            <p>
                                {{
                                    semesters.find(
                                        (sem) => sem.id == item.semesterId
                                    ).name
                                }}
                                -
                                {{
                                    years.find((y) => y.id == item.yearId).name
                                }}
                            </p>

                            <p>
                                {{
                                    facultyList.find(
                                        (fac) => fac.id == item.facultyId
                                    ).name
                                }}
                                -
                                {{
                                    branches.find(
                                        (br) => br.id == item.departmentId
                                    ).name
                                }}
                            </p>
                            <p>
                                {{
                                    universityList.find(
                                        (un) => un.id == item.universityId
                                    ).name
                                }}
                            </p>
                        </div>
                    </b-card-body>

                    <template #footer>
                        <b-button variant="main-color" class="w-100 p-1" @click="goToDetails(item.id)">عرض
                            التفاصيل</b-button>
                    </template>
                </b-card>
            </b-col>
            <div class="d-flex justify-content-center align-items-center flex-column w-100">
                <b-pagination v-model="filterDto.pageIndex" pills :total-rows="course.count" :per-page="filterDto.pageSize"
                    aria-controls="my-card" @change="paginate">
                </b-pagination>
            </div>
        </b-row>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { $themeConfig } from '@themeConfig'
export default {
    data() {
        return {
            appName: $themeConfig.app.appName,
            filterDto: {
                semesterId: "",
                universityId: "",
                facultyId: "",
                yearId: "",
                pageSize: 4,
                pageIndex: 1,
            },
        };
    },
    computed: {
        ...mapState({
            course: ({ courses }) => courses.course,
            subjectList: ({ subjects }) => subjects.subjects,
            universityList: ({ university }) => university.universityList,
            facultyList: ({ faculty }) => faculty.facultyList,
            years: ({ settings }) => settings.yearList,
            semesters: ({ settings }) => settings.semesterList,
            branches: ({ branches }) => branches.branchList,
        }),
    },
    methods: {
        ...mapActions([
            "getCourses",
            "getFacultyList",
            "getUniversityList",
            "getSettingYear",
            "getSettingSemester",
            "getSubjectsList",
            "getBranchList",
        ]),
        fetchData() {
            this.getSubjectsList({
                universityId: "",
                facultyId: "",
                yearId: "",
                semesterId: "",
                pageIndex: 1,
                pageSize: 1000,
            });
        },
        paginate(e) {
            console.log(e);
            this.getCourses({
                ...this.filterDto,
                pageIndex: e,
            });
        },
        goToDetails(id) {
            this.$router.push({ path: `/admin/courses/${id}` });
        },
        resetFilter() {
            Object.assign(this.filterDto, {
                semesterId: "",
                yearId: "",
                facultyId: "",
                universityId: "",
            });
            this.getCourses(this.filterDto);
        },
    },
    created() {
        this.getCourses(this.filterDto);
        this.fetchData();
        this.getFacultyList();
        this.getUniversityList();
        this.getSettingYear();
        this.getSettingSemester();
        this.getBranchList();
        this.getDepartmentsWithSubjects();
    },
};
</script>
<style lang="scss">
.course {
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
    }

    svg {
        fill: #6e6b7b;
    }

    .courses {
        .free {
            top: 10px;
            right: 10px;
        }

        .card-body {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .card-header {
            padding: 0 !important;
        }

        .card-footer {
            padding: 0 !important;
            text-align: center;
            cursor: pointer;

            button {
                color: #fff !important;
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
        }
    }
}
</style>
